@import './Properties.scss';

.App {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: stretch;
    font-family: Poppins;
}

// #FBF2F4 - Background color
// #AB70CA - Purple Shade to be used
