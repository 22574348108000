.scheduleHeader {
    h1 {
        margin: 0;
        font-size: 35px;
        font-weight: bold;
    }

    .schedulePage__description {
        color: #898989;
        display: flex;
        position: relative;
        width: fit-content;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .schedulePage__info {
            display: flex;
            position: absolute;
            right: -260px;
            bottom: 30px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            border: 1px solid #AB70CA;
            box-sizing: border-box;
            border-radius: 16px;
            padding: 10px 20px;

            p {
                color: #000000;
                margin: 2px 0;
                font-size: 14px;
            }

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 30px;
                width: 220px;

                .regular__schedule, .booked__schedule, .consultation__schedule {
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                }

                .regular__schedule {
                    background: #AB70CA;
                }

                .booked__schedule {
                    background: #AB70CA;
                    align-items: center;
                    justify-content: center;
                    div {
                        background: #FFFFFF;
                        height: 5px;
                        width: 14px;
                        border-radius: 4px;
                    }
                }

                .consultation__schedule {
                    background: #FA8A8A;
                }
            }
        }

        h4 {
            font-size: 1em;
            font-weight: bold;
        }
    }
}

@media  screen and (max-width: 800px) {
    .scheduleHeader {
        padding: 0 30px;

        .MuiIconButton-root {
            display: none;
        }
    }
}