.header {
    position: fixed;
    z-index: 2;
    top: 40px;
    right: 20px;
    display: none;
}

@media (max-width: 768px) {
    .header {
        display: block;
    }
}