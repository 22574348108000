.schedulePage {
    width: 100%;
    height: 100vh;

    .schedulePage__content {
        padding: 60px 80px;
        padding-bottom: 0;
    }
}

@media  screen and (max-width: 1000px) {
    .schedulePage {
        width: 100%;
    
        .schedulePage__content {
            padding: 40px;
            padding-bottom: 0;
        }
    }
}

@media  screen and (max-width: 800px) {
    .schedulePage {
        width: 100%;
    
        .schedulePage__content {
            padding: 3.5rem 0;
            padding-bottom: 0;
        }
    }
}
