.Admin {

    background-color: white;
    color:black;
    margin: 0px 20px 20px 20px;

    .Admin__wrapper {
        display: flex;
        flex-direction: row;
        // justify-content: space-around;
        justify-content: space-between;
        // align-items: center;
        // width: fit-content;
        border-radius: 10px;
        
        .Admin__block-about{
            display: flex;
            flex-direction: row;
            width: 60%;
            justify-content: space-between;
            h1{
                font-size: medium;
                // margin-top: 16px;
                // margin-left: 5px;
                display: flex;
                justify-content: first baseline;
                align-items: center;
            }
            .Admin__block-email{
            p {
                font-weight: 450;
                font-size: 15px;
            }
            @media screen and (max-width: 700px) {
                display: flex;
                flex-direction: column;
                p{
                    font-size: 11px;
                    align-items: flex-start;
                    margin-left: 0px;
                }
            }

            @media screen and (max-width: 300px) {
                display: flex;
                flex-direction: column;
                p{
                    font-size: 6px;
                    align-items: flex-start;
                    margin-left: 0px;
                }
            }
          }
          @media screen and (max-width: 700px){
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            h1{
                margin-bottom: 0;
                font-size: 15px;
            }
        }
        @media screen and (max-width: 300px){
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            h1{
                margin-bottom: 0;
                font-size: 10px;
            }
        }
        }
        .Admin_status{
            display: flex;
            align-items: center;
            width: 33%;
            justify-content: flex-end;
            button{
                background-color: #AB70CA;
                color: white;
                padding: 5px 15px;
                border: none;
                border-radius: 7px;
                margin: 0px 5px 0px 0px;
                cursor: pointer;
            }
        }
    }

    .Admin__bio {
        .Admin__readmore {
        }
    }
}
