@import '../../Variables.scss';
@import '../../Properties.scss';

.auth {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    min-height: 745px;
    margin: auto;
    justify-content: center;
    align-items: center;

    .emailWarning {
        background-color: red;
        color: #ffffff;
        width: 100%;
        position: absolute;
        top: 0;
    }

    .MuiCircularProgress-root {
        height: 12px !important;
        width: 12px !important;
        margin-left: 10px;
    }

    .auth-div{
        display: flex;
        flex-direction: row;
        width: 65%;
        height: 85%;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);

        .welcome-div{
            @include font-poppin;
            color: #ffffff;
            background-size: cover;
            background-repeat: no-repeat;
            // width: 23%;
            white-space: nowrap;
            flex: 2;
            background-image: url("../../assets/AuthBG.png");
            border-radius: 15px 0 0 15px;
            padding: 90px 40px;

            .welcome-p{
                font-size: 34px;
                font-weight: 600;
                margin: 0 auto 10px 0;
            }

            p{
                margin: 0;
                font-size: 16px;
            }
        }

        .form-div{
            @include font-poppin;
            padding: 90px 60px;
            flex: 5;
            // .name-div{
            //     display: flex;
            //     flex-direction: row;
            //     justify-content: space-between;
            //     input{
            //         width: 180px;
            //     }
            // }

            .welcome-div-mobile{
                display: none;
            }

            @media screen and (min-width: 1400px){
                .name-div{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    input{
                        width: 250px;
                    }
                }

                .text-n-btn-div{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                .btn-submit{
                    margin-top: 30px;
                }
            }

            .btn-submit {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                padding: 10px 30px;
                width: 140px; 
                @include btn-style;
            }
    
            .btn-submit:hover{
                background-color: $mhp-btn-hover-purple;
            }

            input{
                @include font-poppin;
                width: 100%;
                margin-top: 5px;
                margin-bottom: 20px;
                border: 0;
                border-bottom: solid 1px $mhp-light-gery;
                font-size: 16px;
                padding: 5px 0 5px 0 ;
                font-weight: 500;
                color: $mhp-active-input-grey;
            }

            input::placeholder{
                color: $mhp-inactive-input-grey;;
            }

            input:focus{
                outline: none;
                border-bottom: solid 1px $mhp-dark-grey;
            }

            input::-ms-reveal,
            input::-ms-clear{
                display: none;
            }

            label{
                color: $mhp-light-gery;
            }

            .forgot-password{
                @include animate-hover;
                @include font-poppin;
                margin-top: -15px;
                font-style: italic;
                font-size: 12px;
                color: $mhp-inactive-input-grey;
                font-weight: 500;
                cursor: pointer;

                &:hover{
                    color: $mhp-active-input-grey;
                }
            }

            .signin-up-text{
                margin-top: 50px;
                font-size: 12px;
                color: $mhp-inactive-input-grey;
                font-weight: 500;
                .signin-up-click{
                    color: $mhp-btn-normal-purple;
                    cursor: pointer;

                    &:hover{
                        font-weight: 600;
                        color: $mhp-btn-hover-purple;
                    }
                }
            }

            .signin-up-text-mobile{
                display: none;
            }

            .password-div{
                display: flex;
                flex-direction: row;
                width: 100%;
                border-bottom: solid 1px $mhp-light-gery;
                align-items: center;

                &:focus-within{
                    border-bottom: solid 1px $mhp-dark-grey;
                }

                input{
                    margin: 0;
                    border: 0;
                }

                .visible-icons{
                    fill: $mhp-light-gery;
                    margin-right: 5px;
                    cursor: pointer;
                }
            }

            .google-div{
                margin-top: 100px;
                text-align: center;
                color: $mhp-light-gery;
                .hr-div{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 15px;

                    hr{
                        height: 1px;
                        background-color: $mhp-light-gery;
                        width: 100%;
                        border: none;
                    }
                }

                 
                .google-btn{
                    @include animate-hover;
                    @include font-poppin;
                    margin: 20px auto;
                    width: fit-content;
                    a {
                        font-weight: 500;
                        display: flex;
                        padding: 15px;
                        flex-direction: row;
                        gap: 10px;
                        justify-content: center;
                    }
                    border: 1px solid #ffffff;
                    background-color: #ffffff;
                    box-shadow: 0px 0px 5px $mhp-inactive-input-grey;

                    &:hover{
                        border: 1px solid $mhp-active-input-grey;
                    }
                }
            }
    
        }
    }

    @media screen and (max-width:1400px) {
        .welcome-div{
            .welcome-p{
                font-size: 24px;
                font-weight: 600;
                margin: 0 auto 10px 0;
            }

            p{
                margin: 0;
                font-size: 14px;
            }

        }
        .auth-div{
            width: 75%;
            .name-div{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                input{
                    width: 180px;
                }
            }

            .btn-submit{
                margin-top: 5px;
            }
            
        }
    }

    @media screen and (max-width:1000px){
        .auth-div{
            .name-div{
                display: block;
                input{
                    font-size: 14px !important;
                    width: 100%;
                }
            }

            input{
                font-size: 14px !important;
            }
            label{
                font-size: 14px !important;
            }
            .btn-submit{
                font-size: 14px !important;
            }

            #signup{
                margin-top: 0 !important;
            }

            .google-div{
                gap: 1px !important;
                .google-btn{
                    white-space: nowrap;
                    font-size: 14px !important;
                }
            }
        }

    } 

    


    @media  screen and (max-width: 800px) {
        .auth-div{
            background-color: $mhp-bg-pink;
            width: 100%;
            height: 100%;
            padding: 30px 20px;

            .welcome-div{
                display: none;
            }

            .form-div{
                padding: 20px;
                .welcome-div-mobile{
                    padding: 60px 0 10px;
                    @include font-poppin;
                    font-size: 18px;
                    display: block;
                    color: #000000;
                    font-weight: 600;
                    p{  
                        margin-top: 0px;
                        font-weight: 500;
                        color: #A9A9A9;
                    }

                }

                .forgot-password{
                    text-align: right;
                }
                
                label{
                    @include font-poppin;
                    font-size: 12px;
                    color: $mhp-inactive-mobile-input-grey;

                    &:focus{
                        color: $mhp-active-input-grey;
                    }
                }
                input{
                    @include font-poppin;
                    margin-top: 1px;
                    width: 100%;
                    box-sizing: border-box;
                    background-color: $mhp-bg-pink;
                    border: 1px solid $mhp-inactive-mobile-input-grey;
                    border-radius: 4px;
                    padding: 10px 20px;
                    font-size: 12px;

                    &:focus{
                        border: 1px solid $mhp-btn-normal-purple;
                    }
                }
                border-radius: 0;
                .name-div{
                    display: block;
                    input{
                        box-sizing: border-box;
                        width: 100%;
                    }
                }

                .btn-submit{
                    display: block;
                    width: 100%;
                }

                .password-div{
                    border: 1px solid $mhp-inactive-mobile-input-grey;
                    border-radius: 4px;
                    align-items: center;

                    &:focus-within{
                        border: 1px solid $mhp-btn-normal-purple;
                    }
                    input{
                        margin: 0;
                        border: 0;
                    }
    
                    .visible-icons{
                        fill: $mhp-light-gery;
                        margin-right: 5px;
                        cursor: pointer;
                    }
                }

                .google-div{
                    margin-top: 20px;
                    .google-btn{
                        box-sizing: border-box;
                        width: 100%;
                    }
                }

                .signin-up-text{
                    display: none;
                }

                .signin-up-text-mobile{
                    display: block;
                    text-align: center;
                    margin-top: 30px;
                    font-size: 12px;
                    color: $mhp-inactive-input-grey;
                    font-weight: 500;
                    .signin-up-click{
                        color: $mhp-btn-normal-purple;
                        cursor: pointer;

                        &:hover{
                            font-weight: 600;
                            color: $mhp-btn-hover-purple;
                        }
                    }
                }


            }
        }
        
    }
}