// COLORS

$mhp-btn-normal-purple:#AB70CA;
$mhp-btn-hover-purple:#AA5BD3;
$mhp-bg-pink:#FBF2F4;
$mhp-dark-purple:#381D59;
$mhp-navbar-active-page-orange: #FA8A8A;
$mhp-orange:#FA8A8A;
$mhp-light-gery:#CDCDCD;
$mhp-dark-grey:#898989;
$mhp-inactive-input-grey:#898989;
$mhp-active-input-grey: #4D4D4D;
$mhp-inactive-mobile-input-grey:#C4C4C4;
$mhp-btn-hover-purple-slots:#aa70ca21;
$mhp-btn-hover-orange-slots:#fa8a8a21;