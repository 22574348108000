@import '../../Variables.scss';
@import '../../Properties.scss';

.admin__profile{
    width: 100%;
    background-color: #f8dfe5;
    .admin__profile-body{
        margin: 40px 20px 20px;
        background-color: #FBF2F4;
        border-radius: 7px;
        opacity: 1;
        padding-bottom: 30px;
        h2{ 
            font-size: 30px;
            font-weight: 450;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        p{
            font-weight: 600;
            font-size: large;
            margin-left: 20px;
            margin-right: 20px;
        }
        .admin__profile-title{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    .admin__logout {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin: 20px;
        .btn-submit {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            padding: 10px 30px;
            width: 140px; 
            @include btn-style;
        }
    }
}