.rescheduleAccept {
  .content {
    margin: 20px 0;
    padding: 20px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: rgb(255, 255, 255);
    @media screen and (max-width: 640px) {
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .content__info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 40px;
      @media screen and (max-width: 640px) {
        gap: 20px;
      }
    }
  }
  
  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile_image {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;

    @media screen and (max-width: 640px) {
      height: 70px;
      width: 70px;
    }
  }

  .three {
    padding: 9px;
    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }
  
  .name {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #383838;

    @media screen and (max-width: 640px) {
      font-size: 16px;
    }
  }
  
  .request {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    
    color: #898989;
  }
  
  .time {
    color: #381D59;
    font-weight: 600;
    line-height: 20px;
    text-align: left;

    @media screen and (max-width: 640px) {
      font-size: 12px;
    }
  }
  
  .view {
    background: #AB70CA;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;

    @media screen and (max-width: 640px) {
      margin-top: 4px;
      width: 100%;
    }
  }

  .view:hover {
    background: #8857a3;
  }
}