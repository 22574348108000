@import './Variables.scss';


@mixin animate-hover {
    transition: all .4s ease;
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
}

@mixin font-poppin {
    font-family: 'Poppins', sans-serif;
}

@mixin font-opensans {
    font-family: 'Open Sans', sans-serif;
}

@mixin btn-style {
    @include animate-hover;
    @include font-poppin;
    cursor: pointer;
    color: #ffffff;
    text-align: center;
    background-color: $mhp-btn-normal-purple;
    border: 0;
    border-radius: 7px;
}